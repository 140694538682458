

































































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'

@Observer
@Component({
  components: {
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
    'rct-post-list-item': () => import('@/modules/recruitment/posts/rct-post-list-item.vue'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
    observer: () => import('vue-intersection-observer'),
    'rct-post-pin-list': () => import('@/modules/recruitment/posts/rct-post-pin-list.vue'),
  },
})
export default class PostsSection extends Vue {
  @Inject({}) vm!: CommunityDetailViewModel
  @Watch('$vuetify.breakpoint.width', { immediate: true }) widthChange(value) {
    if (value <= 1480) {
      this.vm.changeListView(true)
    }
  }

  createPostController = createPostController

  loadMore(entry) {
    if (this.vm?.daoDetailPostsCanLoadMore) {
      if (entry.isIntersecting) {
        this.vm.loadMore()
      }
    }
  }
}
